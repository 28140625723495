// Create a multi select2 box from a select field.
//
// The allowCreate parameter defines if the select2 box should
// allow the creation of a new value. If unset, creation isn't
// allowed.
//
// The showCount parameter defines if the the searchbox should
// also display the number of CVs with the given
// jobtitle. Defaults to true.
//
// All other options are passed on to select2, and can override
// any defaults from this function.
export default function jobTitlesConfig(userOptions = {}) {
    // Allow overriding of the default values for allowCreate and
    // showCount. If these values are overridden, don't pass them
    // on to select2.
    let allowCreate = false;
    let showCount = true;
    let mode;
    let limit;
    if (userOptions) {
        if (userOptions['allowCreate'] !== undefined) {
            allowCreate = userOptions['allowCreate'];
            delete userOptions['allowCreate'];
        }
        if (userOptions['showCount'] !== undefined) {
            showCount = userOptions['showCount'];
            delete userOptions['showCount'];
        }
        if (userOptions['limit'] !== undefined) {
            limit = userOptions['limit'];
            delete userOptions['limit'];
        }
        if (userOptions['mode'] !== undefined) {
            mode = userOptions['mode'];
            delete userOptions['mode'];
        }
    }

    const select2_options = {
        multiple: true,
        minimumInputLength: 1,
        ajax: {
            url: "/api/suggestions/cv-data",
            dataType: 'json',
            delay: 250,
            data: (params) => {
                return {
                    type: 'jobtitles',
                    q: params.term,
                    limit: limit,
                    mode: mode,
                };
            },
            processResults: (data) => {
                const res = [];
                data.result.forEach((el) => {
                    res.push({ id: el.id, text: showCount ? (el.label || el.value + " (" + el.count + ")") : el.value });
                });
                return { results: res };
            },
            cache: true,
        },
        width: "100%",
        dropdownCssClass: "bigdrop",
        containerCssClass: "form-control",
        ...userOptions, // UserOptions override the default options and are therefore merged last
    };

    // If we should allow creation, handle title creation
    if (allowCreate) {
        select2_options.tokenSeparators = [','];
        select2_options.tags = true;
        select2_options.createTag = (query) => {
            const trimmed = query.term.trim();
            if (trimmed === "") { return null; }
            return { id: trimmed, text: trimmed };
        };
    }

    return select2_options;
}
