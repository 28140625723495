<script setup>
import { ref, computed, watch } from 'vue';

const props = defineProps({
    /**
     * The number to display.
     */
    number: {
        type: Number,
        default: null,
    },
    /**
     * String to display when `number` is null.
     */
    default: {
        type: String,
        default: "",
    },
    /**
     * Format the number using this function.
     */
    formatter: {
        type: Function,
        default: null,
    },
    /**
     * Do not animate the initial value of the number (from 0).
     */
    skipInitial: {
        type: Boolean,
        default: false
    },
    /**
     * Add this class to the element while animating.
     */
    animatingClass: {
        type: String,
        default: undefined,
    },
});

const emit = defineEmits(['animation-begin', 'animation-end']);

const displayNumber = ref(null);
const isAnimating = ref(false);

const spanClass = computed(() => {
    if (props.animatingClass && isAnimating.value) {
        return [props.animatingClass];
    }
    return [];
});

const formattedDisplayNumber = computed(() => {
    if (displayNumber.value === null) {
        return props.default;
    }

    if (props.formatter === null) {
        return displayNumber.value;
    }

    return props.formatter(displayNumber.value);
});

function stepAnimation() {
    const diff = props.number - displayNumber.value;
    if (Math.abs(diff) < 1) {
        isAnimating.value = false;
        displayNumber.value = props.number;
        emit('animation-end');
    } else {
        let change = diff / 10;
        change = change >= 0 ? Math.ceil(change) : Math.floor(change);
        displayNumber.value += change;
        requestAnimationFrame(stepAnimation);
    }
}

watch(
    () => props.number,
    () => {
        if (props.number === null) {
            displayNumber.value = null;
            return;
        }

        if (props.number === displayNumber.value) {
            return;
        }

        if (props.skipInitial && displayNumber.value === null) {
            displayNumber.value = props.number;
            return;
        }

        if (!Number.isFinite(displayNumber.value)) {
            displayNumber.value = 0;
        }

        // Round display number so the fractional part is reset during animation.
        displayNumber.value = Math.round(displayNumber.value);

        isAnimating.value = true;
        emit('animation-begin');
        requestAnimationFrame(stepAnimation);
    },
    { immediate: true },
);
</script>

<template>
    <!-- #4392A0 = Ocean 80 -->
    <span :class="spanClass" style="color: #4392A0">{{ formattedDisplayNumber }}</span>
</template>
