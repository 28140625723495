import { __, __p } from 'JIX/gettext.js';

export default {
    chooseJobtitlesText: () => __("Din jobtitel"),
    chooseGeoAreasText: () => __("Område"),
    chooseEducationLevelText: () => __("Uddannelse"),
    chooseJobexperienceText: () => __("Erhvervserfaring"),
    chooseMgrexperienceText: () => __p("Abbrev. in English", "Ledelseserfaring"),
    chooseAgeText: () => __("Alder"),
    ageText: () => __("år"),
    updateText: () => __("Opdatér"),
    resetText: () => __("Nulstil"),
    readMoreText: () => __("Læs mere"),
    readLessText: () => __("Læs mindre"),
    numberOfCVsText: () => __("Antal CV'er"),
    explanationText1: () => __("Grafen viser, hvordan lønniveauet fordeler sig for din søgning. Du kan fx se, hvad flest tjener, og om der er stor forskel på højeste og laveste løn."),
    explanationText2: () => __("Lønniveauet er beregnet ud fra lønoplysninger fra CV'er i vores database. Tallene er brutto (inkl. pension) og er oplyst af brugerne selv. Vi kan ikke garantere for nøjagtigheden."),
    averageSalaryText: () => __("Gennemsnitlig månedsløn"),
    krText: () => __("kr."),
    otherJobtitlesHeader: () => __('Andre har tjekket lønniveauet for disse jobtitler'),
    jobsearchCardHeader: () => __('Tid til et jobskifte?'),
    jobsearchCardText: () => __('Væsentlige lønstigninger sker ofte, når folk skifter job. Find dit nye job her.'),
    searchJob: () => __('Søg job'),
    createCvHeader: () => __('Opret dit CV her og bliv set'),
    createCvText: () => __('Virksomheder benytter dagligt vores CV-database til at finde interessante profiler, som de gerne vil i dialog med. Skal du være én af dem? Opret dit CV nu og hjælp os med at forbedre lønberegneren ved at indtaste din løn – helt anonymt.'),
    createCv: () => __('Opret CV'),
};
