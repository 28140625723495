import defaultsDeep from 'lodash/defaultsDeep';
import { getGenericChartConfig } from 'JIX/highcharts/configs/generic_chart.js';

export function getStandardColumnChartConfig(options = {}) {
    const default_config = getGenericChartConfig({
        title: {
            text: "Column Chart",
        },
        chart: {
            type: 'column',
        },
        xAxis: {
            tickWidth: 1,
        },
        yAxis: {
            min: 0,
        },
        plotOptions: {
            column: {
                stacking: 'normal'
            },
        },
        legend: {
            enabled: false,
        },
    });

    // Mutates 'options' with any 'default_config' options
    // that are missing from 'options'
    defaultsDeep(options, default_config);

    return options;
}
