<script>
export default {
    inheritAttrs: false,
};
</script>

<script setup>
import { toRef, computed, useAttrs, mergeProps } from 'vue';

import SvgIcon from 'JIX/components/SvgIcon.vue';

import 'icons/linkarrow-right.svg';

const props = defineProps({
    linkClass: {
        type: [String, Array],
        required: false,
        default: null,
    },
    secondary: {
        type: Boolean,
        default: false,
    },
});
const attrs = useAttrs();

const linkClass = toRef(props, 'linkClass');
const outerAttrs = computed(() => mergeProps(
    { class: attrs.class },
    { class: { 'jix-link-block-secondary': props.secondary } }));
const innerAttrs = computed(() => ({ ...attrs, class: linkClass.value }));
</script>

<template>
    <div class="jix-link-block" v-bind="outerAttrs">
        <a v-bind="innerAttrs">
            <span><slot /></span>
            <span><SvgIcon size="sm" name="linkarrow-right" /></span>
        </a>
    </div>
</template>
