export const ThemeColors = Object.freeze({
    PRIMARY:   Symbol('primary'),
    SECONDARY: Symbol('secondary'),
    SUCCESS:   Symbol('success'),
    INFO:      Symbol('info'),
    WARNING:   Symbol('warning'),
    DANGER:    Symbol('danger'),
    BRAND:     Symbol('brand'),
});
const _validColors = Object.values(ThemeColors);

const HEX_RE = new RegExp("^#([a-f0-9]{2})([a-f0-9]{2})([a-f0-9]{2})$", "i");
const HEX_SHORT_RE = new RegExp("^#([a-f0-9])([a-f0-9])([a-f0-9])$", "i");
const RGB_RE = new RegExp("^rgb\\((.+?),\\s*(.+?),\\s*(.+?)\\)$", "i");
const RGBA_RE = new RegExp("^rgba\\((.+?),\\s*(.+?),\\s*(.+?),\\s*(.+?)\\)$", "i");

function dec2hex(dec) {
    return dec.toString(16).padStart(2, '0');
}

export class Color {
    constructor(red, green, blue, opacity = 0) {
        this.red = Number(red);
        this.green = Number(green);
        this.blue = Number(blue);
        this.opacity = Number(opacity);
    }

    toString() {
        return this.opacity > 0
            ? `rgba(${this.red}, ${this.green}, ${this.blue}, ${this.opacity})`
            : `#${dec2hex(this.red)}${dec2hex(this.green)}${dec2hex(this.blue)}`;
    }
}

let styles;
export function getThemeColor(themeColor) {
    if (!styles) {
        styles = getComputedStyle(document.documentElement);
    }

    if (!_validColors.includes(themeColor)) {
        throw new Error("Invalid argument");
    }

    return parseColor(styles.getPropertyValue(`--${themeColor.description}`).trim());
}

export function colorVariant(color, level = 1) {
    const darken = level > 1;
    const matte = darken ? new Color(0, 0, 0) : new Color(255, 255, 255);
    level = darken ? 2 - level : level;
    const level2 = (1 - level).toFixed(2); // HACK: Round to two decimals to avoid (1 - .9) = 0.09999999999999998
    return new Color(
        Math.round(color.red   * level + matte.red * level2),
        Math.round(color.green * level + matte.green * level2),
        Math.round(color.blue  * level + matte.blue * level2),
        color.opacity * level + matte.opacity * level2,
    );
}

// Source: https://stackoverflow.com/a/47355187
export function normalizeColor(str) {
    const ctx = document.createElement('canvas').getContext('2d');
    ctx.fillStyle = str;
    return ctx.fillStyle;
}

export function parseColor(color) {
    let match = color.match(HEX_RE);
    if (match) {
        return new Color(
            parseInt(match[1], 16),
            parseInt(match[2], 16),
            parseInt(match[3], 16),
        );
    }

    match = color.match(HEX_SHORT_RE);
    if (match) {
        return new Color(
            parseInt(`${match[1]}${match[1]}`, 16),
            parseInt(`${match[2]}${match[2]}`, 16),
            parseInt(`${match[3]}${match[3]}`, 16),
        );
    }

    match = color.match(RGB_RE);
    if (match) {
        return new Color(match[1], match[2], match[3]);
    }

    match = color.match(RGBA_RE);
    if (match) {
        return new Color(match[1], match[2], match[3], match[4]);
    }

    return parseColor(normalizeColor(color));
}
