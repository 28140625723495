import { createApp } from 'vue';

import SalaryIndex from 'JIX/components/salaryindex/SalaryIndex.vue';
import HighchartsVue from 'highcharts-vue';

import Highcharts from 'highcharts';
import { getGlobalChartOptions } from 'JIX/highcharts/configs/generic_chart.js';

const stash = window.Stash['salaryindex/default'];

Highcharts.setOptions(getGlobalChartOptions());

const app = createApp(SalaryIndex, stash);
app.use(HighchartsVue, { tagName: 'Highcharts' });
app.mount('#app');
